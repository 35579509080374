import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
import './Visit_Request_Form.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Connection from '../../connection';
import PulseLoader from "react-spinners/PulseLoader";
import moment from 'moment';

function Visit_Request_Form({ handleClose }) {
    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);

    const [doctor_id, setDoctor_id] = React.useState('');

    const [dr_name, setDr_name] = useState('');
    const [clinic_name, setClinic_name] = useState('');
    const [apt_request_date, setApt_request_date] = useState('');
    const [apt_request_time, setApt_request_time] = useState('');


    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const name = parsed[0].name;

        setDoctor_id(id)
        setDr_name(name)
    }, [])



    const Add_visit_form = () => {
        if (dr_name == '') {
            alert("Please Enter Doctor Name.");
        }
        else if (clinic_name == '') {
            alert("Please Enter Clinic Name.");
        }
        else if (apt_request_date == '') {
            alert("Please Select Apt. Request Date.");
        }
        else if (apt_request_time == '') {
            alert("Please Select Apt. Request Time.");
        }
        else {
            setSpinner(true)
            let uploaddata = new FormData();

            let user_date = moment(new Date()).format("YYYY-MM-DD hh:mm A");

            uploaddata.append("doctor_id", doctor_id)
            uploaddata.append("dr_name", dr_name);
            uploaddata.append("clinic_name", clinic_name);
            uploaddata.append("apt_request_date", apt_request_date);
            uploaddata.append("apt_request_time", apt_request_time);
            uploaddata.append("user_date", user_date);

            let api = Connection + "rest_apis.php?action=Add_form_visit";
            console.log("pass => ", api);

            fetch(api, {
                method: "POST",
                headers: {
                    // "Content-Type": "multipart/form-data",
                    // otherHeader: "foo",
                },
                body: uploaddata,
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log("response", response.response);

                    if (response.response == "fail") {
                        alert("Something went wrong.")
                    }
                    else {
                        toast.success('You have successfully add visit form.');
                        handleClose(false)
                    }
                    setSpinner(false)
                })
                .catch((error) => {
                    console.error(error);
                    setSpinner(false)
                    alert("Something went wrong.")
                });
        }
    };


    return (
        <div className="payout_appointground">

            <div className="payoutapointContainer">
                <div className="titlepayoutapointCloseBtn">
                    <button onClick={() => { handleClose(false); }}>
                        <ArrowBackIcon />
                    </button>
                    <h3>Visit Request Form</h3>
                </div>

                <h3 id='paydocumention'>Complete the form below to request a HEDIS rep visit at your office. Typical visits are quick and very informative. We can assist with data submission and scorecards.</h3>


                <div className="label">Dr. Name</div>
                <input
                    type="text"
                    placeholder="Dr. Name"
                    className="phoneinput"
                    value={dr_name}
                    onChange={(e) => setDr_name(e.target.value)}
                />

                <div className="label">Clinic Name</div>
                <input
                    type="text"
                    placeholder="Clinic Name"
                    className="phoneinput"
                    value={clinic_name}
                    onChange={(e) => setClinic_name(e.target.value)}
                />

                <div className="label">Apt. Request Date</div>
                <input
                    type="date"
                    placeholder="Apt. Request Date"
                    className="phoneinput"
                    value={apt_request_date}
                    onChange={(e) => setApt_request_date(e.target.value)}
                />

                <div className="label">Apt. Request Time</div>
                <input
                    type="time"
                    placeholder="Apt. Request Time"
                    className="phoneinput"
                    value={apt_request_time}
                    onChange={(e) => setApt_request_time(e.target.value)}
                />


                <div onClick={() => Add_visit_form()} className='payout_btn'>
                    <p>Submit Request</p>
                </div>

                <ToastContainer />
            </div>

            {spinner == true &&
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#00a0f8"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#00a0f8', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}

export default Visit_Request_Form;
