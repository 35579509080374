import React, { useState, useEffect, createContext, memo } from 'react'
import Connection from "./connection";
import './App.css';
import Navbar from './MyComponent/Navbar/Navbar';
import { firebaseApp, messaging } from './firebase';
import { onMessage } from 'firebase/messaging';
import { useToasts } from 'react-toast-notifications';
import { Link, parsePath, useNavigate } from "react-router-dom"
import audioFile from './assets/nokia.mp3';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Chat from './pages/Chat/Chat';
import Delete_Account from './pages/Delete_Account/Delete_Account';
import Upload_Xlsx from './pages/Upload_Xlsx/Upload_Xlsx';

import Privatecom from './pages/Privatecom'


import { Routes, Route, Navigate } from "react-router-dom"

export const GlobalInfo = createContext()

function App() {
   const { addToast, removeToast, removeAllToasts } = useToasts();
   const navigate = useNavigate();

   const [auth, setAuth] = useState('');


   const loginenter = () => {
      const auth = localStorage.getItem("customer");
      setAuth(auth)
   }

   useEffect(() => {
      navigator.serviceWorker.addEventListener('message', (event) => {
         console.log('Received message from service worker:', event.data);
         if (event.data.type === 'navigate') {
            window.focus();
            const url = event.data.payload;
            showToast(url)
         }
      });

      onMessage(messaging, (payload) => {
         console.log('Message received. ', payload);

         let noti_name;
         if (payload.notification && payload.notification.title) {
            noti_name = payload.notification.title;
         }
         else if (payload.data && payload.data.title) {
            noti_name = payload.data.title;
         }

         if (noti_name === "Incoming Call") {
            showToast(payload)
         }
         else {
            const notification = new Notification(payload.notification.title, {
               body: payload.notification.body,
               icon: payload.notification.icon,
            });
            notification.onclick = () => {
               console.log('Notification clicked.');
            };
         }
      });

      loginenter()

   }, [addToast]);



   const showToast = (payload) => {
      const callerName = payload.data.username;
      const callertoken = payload.data.tokenA;
      const callingTone = new Audio(audioFile);
      callingTone.play();

      const { id } = addToast(
         <div>
            <strong>Incoming call from {callerName}</strong>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: 10 }}>
               <button
                  style={{ border: '#30a171 1px solid', backgroundColor: '#e3fcef', borderRadius: 8, padding: '5px 10px 5px 10px', color: '#30a171' }}
                  onClick={() => answerCall(payload, callingTone, callertoken)}
               >
                  Answer
               </button>
               <button
                  style={{ border: 'red 1px solid', backgroundColor: '#e3fcef', borderRadius: 8, padding: '5px 10px 5px 10px', color: 'red' }}
                  onClick={() => rejectCall(payload, id, callingTone)}
               >
                  Reject
               </button>
            </div>
         </div>,
         {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 30000,
            onDismiss: (id) => rejectCall(payload, id, callingTone),
         }
      );

      // Save or use the id as needed
      console.log('Toast ID:', id);
   };


   const answerCall = (payload, callingTone, callertoken) => {
      console.log('Answering call:', payload);

      callingTone.pause();
      callingTone.currentTime = 0;

      removeAllToasts()
      const url = `/AgoraCall?callertoken=${callertoken}`;
      navigate(url);
      // navigate("/AgoraCall")
      addToast('Call answered!', { appearance: 'success', autoDismiss: true });
   };

   const rejectCall = (payload, toastId, callingTone) => {
      console.log('Rejecting call:', toastId);

      callingTone.pause();
      callingTone.currentTime = 0;

      removeAllToasts()
      // removeToast(toastId);
      addToast('Call rejected!', { appearance: 'error', autoDismiss: true });
   };


   return (

      <GlobalInfo.Provider value={{ loginenter: loginenter }}>
         {!auth ? (
            <Routes>
               <Route path='/login' element={<Login />}></Route>
               <Route path='/*' element={<Login />}></Route>
            </Routes>
         ) : (
            <div className='topbar_page'>
               <Routes>
                  <Route element={<Privatecom />} >
                     <Route path='/' element={<Home />}></Route>
                     <Route path='/Chat' element={<Chat />}></Route>
                     <Route path='/Delete_Account' element={<Delete_Account />}></Route>
                     <Route path='/Upload_Xlsx' element={<Upload_Xlsx />}></Route>
                     <Route path='/*' element={<Navigate to={'/'} />}></Route>
                  </Route>
               </Routes>

            </div>
         )}
      </GlobalInfo.Provider>

   );
}

export default memo(App);