import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './Services_Detail.css';
import 'react-toastify/dist/ReactToastify.css';

function Services_Detail({ serviceClose }) {
    const navigate = useNavigate();

    const [role, setRole] = React.useState('');


    useEffect(() => {
        const auth = localStorage.getItem("customer");
        const parsed = JSON.parse(auth);
        const id = parsed[0].id;
        const role = parsed[0].role;

        setRole(role)
    }, [])


    const text = '713-900-4450';
    const brokenText = text.split('').map((char, index) => (
        <span key={index} onClick={() => alert('Calling 713-900-4450')} style={{ color: '#00a0f8', fontSize: 15, marginBottom: -5, fontWeight: 'bold', cursor: 'pointer' }}>
            {char}
        </span>
    ));

    const text1 = 'admin@acprofessional.com';
    const brokenText1 = text1.split('').map((char, index) => (
        <span key={index} onClick={() => alert('Opening Email Client')} style={{ color: '#00acfc', fontSize: 15, marginBottom: -5, fontWeight: 'bold', cursor: 'pointer' }}>
            {char}
        </span>
    ));

    const text2 = '866-311-1916';
    const brokenText2 = text2.split('').map((char, index) => (
        <span key={index} onClick={() => alert('Calling 866-311-1916')} style={{ color: '#00acfc', fontSize: 15, marginBottom: -5, fontWeight: 'bold', cursor: 'pointer' }}>
            {char}
        </span>
    ));


    const chat_romm = async () => {
        document.body.style.overflow = 'auto';

        const check_chat = 'do_chat'
        const url = `/Chat?check_chat=${check_chat}&chat_name=${"John Smith"}&receiver_id=${"1"}`;
        navigate(url);
    }

    return (
        <div className="services_detailground">

            <div className="servicedetailContainer">
                <div style={styles.header}>
                    <span onClick={() => serviceClose(false)} style={styles.icon}>←</span>
                    <span style={{ color: 'black', fontSize: 15, fontWeight: 700 }}>HEDIS Score</span>
                    <span style={{ visibility: 'hidden' }}>Place</span>
                </div>

                <div style={styles.container}>
                    <p style={{ color: 'black' }}>There are several ways you can submit data for HEDIS credit, thus improving your overall score card. We accept the following three methods:</p>
                    <img style={styles.image} src={require('../../assets/hedis_don.png')} alt="HEDIS Don" />

                    <p style={styles.bodyText}>⦁   Fax to {brokenText} - Fax HEDIS Gap Patient Records or proof of physician visit.</p>
                    <p style={{ color: 'black', marginTop: 5 }}>⦁   Email to {brokenText1}.</p>
                    <p style={{ color: 'black', marginTop: 5 }}>⦁   Schedule an office visit and we will send a rep to retrieve the data.</p>

                    <p style={{ color: 'black', marginTop: 25 }}>Do not wait until the end of the year to submit your HEDIS data. It is best practice to try and close any GAPS at the end of every month. If you need assistance, please give us a call at {brokenText2} or email {brokenText1}. We are here to help!</p>
                </div>


                {role != 'admin' &&
                    <span onClick={() => chat_romm()} className='chatfromservic_btn'>
                        <p>CHAT WITH US</p>
                    </span>
                }

            </div>

        </div>
    );
}

const styles = {
    header: {
        padding: '15px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)',
        backgroundColor: 'white',
    },
    icon: {
        color: '#1B2E59',
        fontSize: 20,
        cursor: 'pointer'
    },
    bodyText: {
        color: 'black',
        marginTop: 15,
    },
    image: {
        width: '100%',
        height: 150,
        alignSelf: 'center',
        objectFit: 'contain',
        marginTop: 15,
    },
    container: {
        marginTop: 10,
        padding: 15,
        marginBottom: 40
    },
};

export default Services_Detail;
