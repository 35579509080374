import React, { useState, useEffect, useRef, useContext } from 'react'
import Connection from "../../connection";
import "./Upload_Xlsx.css";
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PulseLoader from "react-spinners/PulseLoader";
import axios from 'axios';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function Upload_Xlsx() {
    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('Empty File');


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.xlsx')) {
            setSelectedFile(file);
            setUploadStatus("File Selected: " + file.name);
        }
        else {
            setUploadStatus("Please select an XLSX file.");
            alert("Please select an XLSX file.");
        }
    };

    const onFileUpload = () => {
        if (selectedFile) {
            setSpinner(true)

            const formData = new FormData();
            formData.append('excel_file', selectedFile);
            axios.post(Connection + 'rest_apis.php?action=upload_excel', formData)
                .then(response => {

                    if (response.data.response === 'success') {
                        setUploadStatus('File uploaded successfully!');
                        toast.success("File uploaded successfully!");

                        // setTimeout(() => {
                        //     BacktoHome()
                        // }, 2000);
                    }
                    else {
                        setUploadStatus('Failed to upload file.');
                        alert("Failed to upload file.");
                    }
                    setSpinner(false)
                })
                .catch(error => {
                    console.error('There was an error uploading the file!', error);
                    setUploadStatus('Error occurred during upload.');
                    alert("Error occurred during upload.");
                    setSpinner(false)
                });
        }
        else {
            alert("Please select an XLSX file.");
        }
    };


    const BacktoHome = () => {
        navigate('/')
    }

    return (

        <div className="Delete_Background">

            <div className="DelteContainer">
                <div style={{ width: '100%', backgroundColor: '#00a0f8', padding: '15px 10px', display: 'flex', alignItems: 'center', flexDirection: 'row', color: 'white' }}>
                    <div onClick={BacktoHome}>
                        <KeyboardBackspaceIcon color='white' style={{ marginTop: 5 }} />
                    </div>
                    <div style={{ marginLeft: '10px', justifyContent: 'center' }}>
                        <p className='delete_text'>Upload Excel File</p>
                    </div>
                </div>


                <div className="custom_file_input">
                    <input type="file" onChange={onFileChange} />
                    <label className='prfilebtn'>Select Excel File</label>
                </div>

                <p style={{ color: 'black', marginBottom: 80 }}>Status: {uploadStatus}</p>

                <div className="deletebuttoContainer">
                    <button onClick={() => onFileUpload()} className="customButton">
                        Upload File
                    </button>
                </div>
            </div>


            <ToastContainer />


            {spinner == true &&
                <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
                        <PulseLoader
                            color={"#00a0f8"}
                            loading={spinner}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{ fontSize: '16px', color: '#00a0f8', fontWeight: 'bold', marginLeft: '15px' }}>
                            Progressing your request
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}