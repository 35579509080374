import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Link, parsePath, useParams, useNavigate } from "react-router-dom"

import './Login.css';
import { GlobalInfo } from "../../App"
import Connection from "../../connection";

function Login() {
  const { loginenter } = useContext(GlobalInfo)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isChecked, setIsChecked] = React.useState(true);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  useEffect(() => {
    let remember = localStorage.getItem('remember');
    if (remember != null) {
      let parsed = JSON.parse(remember);
      let email_1 = parsed[0];
      let password_1 = parsed[1];

      setEmail(email_1)
      setPassword(password_1)
      setIsChecked(true)
    }
  }, [])



  const Login = async (event) => {
    event.preventDefault();

    let uploaddata = new FormData();

    if (!email) {
      alert('Please Enter Email.');
    }
    else if (!password) {
      alert('Please Enter Password.');
    }
    else {
      setIsLoading(true);

      uploaddata.append('email', email);
      uploaddata.append('password', password);

      let api = Connection + 'rest_apis.php?action=login_1';
      console.log("Api for loogin => ", api)
      try {
        const response = await fetch(api, {
          method: 'POST',
          headers: {
            // "Content-Type": "multipart/form-data",
            // "otherHeader": "foo",
          },
          body: uploaddata,
        })


        const jsonResponse = await response.json();
        console.log("jsonResponse.response", jsonResponse.response)


        if (jsonResponse.response === "fail") {
          setIsLoading(false);
          alert("Invalid Email or Password.")
        }

        else {
          let status = jsonResponse.response[0].status


          if (status == 'approved') {
            if (isChecked == true) {
              let table = []
              table.push(email, password)
              console.log('taaaaaaabbbbbbbbbbbble', table)
              localStorage.setItem("remember", JSON.stringify(table))
            }
            else {
              localStorage.removeItem("remember");
            }
            setIsLoading(false);
            localStorage.setItem("customer", JSON.stringify(jsonResponse.response))
            loginenter()
          }
          else {
            setIsLoading(false);
            alert("You have successfully registered but your account is under review.")
          }

        }

      } catch (error) {
        console.error(error);
        setIsLoading(false);
        alert("Something went wrong.")
      }
    }
  }


  return (
    <div className="login_container">
      <div className="login_card">
        <h2>Login</h2>
        {/* {error && <p className="error">{error}</p>} */}
        <form onSubmit={Login}>
          <div className="form_first_group">
            <input
              // type="email"
              className="form_control"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>
          <div className="form_group">
            <input
              type="password"
              className="form_control"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>

          <div className='login_remenbr'>
            <input className={isChecked ? 'custom_checkbox_active' : 'custom_checkbox_inactive'} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
            <h4>Remember Me</h4>
          </div>

          {isLoading ? (
            <div className="loader_container">
              <div className="loader loader-primary"></div>
            </div>
          ) : (
            <button type="submit" className="butn btn-primary">
              Login
            </button>
          )}
        </form>
        <p className="text_center mt_3">
          Don't have an account? <a href="#">Sign up</a>
        </p>
      </div>
    </div>

  );
};

export default Login;